import { UserAuthenticationService } from '@ohif/core';
const userServices = UserAuthenticationService.REGISTRATION.create({ configuration: {} });

const KEY = 'flex-view-access_token';
const BASEURL_KEY = 'flex-view-domain';
const DATA_SOURCE_TOKEY = 'flex-view-data-source-token';
const EXTRA_HEADERS = 'flex-view-extra-headers';

const onTokenChangeCallback = (
  reload: boolean,
  event: {
    data: {
      token: string;
      baseUrl: string;
      dataSourceToken: string;
      extraHeaders: any;
    };
  }
) => {
  performance.mark(`token:onTokenChangeCallback`);
      const changed = getAuthToken() !== event?.data?.token;
  localStorage.setItem(KEY, event?.data?.token);
  localStorage.setItem(BASEURL_KEY, event?.data?.baseUrl);
  localStorage.setItem(DATA_SOURCE_TOKEY, event?.data?.dataSourceToken || '');
      localStorage.setItem(EXTRA_HEADERS, event?.data?.extraHeaders || '{}');
  if (reload && changed) {
        window.location.reload();
      }
};

export function subscribeToLogin(): void {
  function onMessage(event) {
    if (event?.data?.msg === 'flexViewToken') {
      onTokenChangeCallback(true, event);
    }
  }
  window.addEventListener('message', onMessage, false);
}
export function loadTokenFromParentWindow() {
  const perfomanceMarkName = 'token:loadTokenFromParentWindow';
  performance.mark(`${perfomanceMarkName}-start`);
  const tokenPromise = new Promise(resole => {
    function onMessage(event) {
      if (event?.data?.msg === 'flexViewTokenChanged') {
        performance.mark(`${perfomanceMarkName}-end`);
        performance.measure(
          perfomanceMarkName,
          `${perfomanceMarkName}-start`,
          `${perfomanceMarkName}-end`
        );
        onTokenChangeCallback(false, event);
        resole(event?.data);
      }
    }
    window.addEventListener('message', onMessage, false);
    performance.mark('token:posting-message-to-parent');
    window?.parent?.postMessage({ msg: 'flexViewTokenMissing' }, '*');
  });

  const timeoutPromise = new Promise(resolve => {
    performance.mark('token:timeout-start');
    setTimeout(() => {
      if (getAuthToken()) {
        return resolve(getAuthToken());
      }
      performance.mark('token:timeout-no-success');
      return Promise.reject(new Error('Timeout waiting for token'));
    }, 1000);
  });
  //Will resolve the first promise that resolves or rejects doing that to avoid waiting forever for the token
  return Promise.race([tokenPromise, timeoutPromise]);
}

export async function loadViewerConfig(retryTimes: number = 0): Promise<any> {
  if (!getAuthToken()) {
    performance.mark('token:no-token-trying-to-load-from-parent');
    await loadTokenFromParentWindow();
  }

  const response = await fetch(`${getBaseUrl()}/imaging/viewer-config`, {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const result = await response.json();
  if (result?.message === 'Unauthorized' && retryTimes < 3) {
    await loadTokenFromParentWindow();
    return loadViewerConfig(retryTimes + 1);
  }
  return result;
}

export async function postEventLog(event: any): Promise<any> {
  const response = await fetch(`${getBaseUrl()}/event-log`, {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      type: event.type,
      payload: event.payload,
    }),
  });
  const result = await response.json();
  return result;
}

export function getAuthToken(): string {
  return localStorage.getItem(KEY);
}

export function getBaseUrl(): string {
  return localStorage.getItem(BASEURL_KEY);
}

export function getDatasourceToken(): string {
  return localStorage.getItem(DATA_SOURCE_TOKEY);
}

export function getExtraHeaders(): string {
  return JSON.parse(localStorage.getItem(EXTRA_HEADERS));
}

window.loadFlexViewConfig = loadViewerConfig;
window.setUpTokenLoader = function setUpTokenLoader(config, dataSourceConfig) {
  if (
    (
    dataSourceConfig?.configuration?.flexViewAuth ||
    dataSourceConfig?.configuration?.access_token
    )
    &&
    config.defaultDataSource === dataSourceConfig.sourceName
  ) {
    const access_token =
      getDatasourceToken() ||
      dataSourceConfig?.configuration?.access_token ||
      getAuthToken();
    const headers = Object.assign(getExtraHeaders(), {
      Authorization: `Bearer ${access_token}`,
    });
    return userServices.setServiceImplementation({
      getAuthorizationHeader: () => headers,
    });
  }
};
